/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 11:26:26
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 11:27:11
 */
import Bar from './bar.vue'
export default Bar
