/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 11:13:00
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 11:24:41
 */
import BarChart from './barChart.vue'
export default BarChart
