/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-18 18:46:07
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-18 19:14:41
 */
import Car from './car.vue'
export default Car
