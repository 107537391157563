import { render, staticRenderFns } from "./barChart.vue?vue&type=template&id=0be99e1c&scoped=true&"
import script from "./barChart.js?vue&type=script&lang=js&"
export * from "./barChart.js?vue&type=script&lang=js&"
import style0 from "./barChart.scss?vue&type=style&index=0&id=0be99e1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be99e1c",
  null
  
)

export default component.exports