/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 11:12:47
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 17:43:37
 */
import NBar from './components/bar'
import NoData from '../noData'

export default {
  name: 'BarChart',
  components: {
    NBar,
    NoData
  },
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    titleStyle: {
      type: Object
    },
    valueStyle: {
      type: Object
    },
    showLine: {
      type: Boolean,
      default: false
    },
    startValue: {
      type: String,
      default: '0%'
    },
    endValue: {
      type: String,
      default: '100%'
    },
    bottomTitle: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasData () {
      return true
    }
  }
}
