/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-19 11:26:43
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-19 20:16:06
 */
export default {
  name: 'Bar',
  props: {
    title: {
      type: String,
      default: '类名'
    },
    value: {
      type: String,
      default: ''
    },
    percent: {
      type: String,
      default: '50%'
    },
    bgColors: {
      type: Array,
      default: () => (['rgba(44, 104, 255, 0.08)'])
    },
    titleStyle: {
      type: Object
    },
    valueStyle: {
      type: Object
    },
    barWidth: {
      type: Number,
      default: 16
    },
    roundCap: {
      type: Boolean,
      default: true
    },
    barColors: {
      type: Array,
      default: () => (['rgba(45, 184, 255, 1)', 'rgba(20, 130, 255, 1)'])
    }
  },
  data () {
    return {
      startMove: false
    }
  },
  computed: {
    barHeight () {
      return this.barWidth + 'px'
    },
    barRadius () {
      return this.barWidth / 2 + 'px'
    },
    bgBackground () {
      return this.color(this.bgColors)
    },
    barBaackground () {
      return this.color(this.barColors)
    },
    bgStyle () {
      const self = this
      return {
        height: self.barHeight,
        borderRadius: self.barRadius,
        background: self.bgBackground
      }
    },
    barStyle () {
      const self = this
      return {
        height: self.barHeight,
        width: self.percent,
        borderRadius: self.barRadius,
        background: self.barBaackground
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.startMove = true
    })
  },
  methods: {
    color (colors) {
      const [color, endColor] = colors
      return endColor ? ('linear-gradient(90deg, ' + color + ' 0%, ' + endColor + ' 100%)') : color
    }
  }
}
